var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-link',{staticClass:"user-card-widget d-flex flex-column\n      align-items-center bg-white overflow-hidden",attrs:{"to":!_vm.urlOpenNewWindow ? _vm.handleBaseUrl : null},on:{"click":function($event){return _vm.onCardClick(_vm.handleBaseUrl)}}},[(_vm.ActionRuleService.isRuleValid(_vm._actions, _vm.ActionRule.CAN_CONNECT)
        && _vm.hasConnectionFeature)?_c('div',{staticClass:"d-flex justify-content-end w-100 position-absolute connection-button"},[_c('div',{staticClass:"position-relative"},[(_vm.buttonsEnabled && _vm.isProfileVisible)?_c('button-icon-component',{staticClass:"w-fit ml-auto mr-2 mt-2",attrs:{"id":("connect-" + _vm.uid),"variant":_vm.ourConnection ? 'alt-2' : 'tertiary',"shape":"round","size":"md"},on:{"on-click":_vm.toggleModal},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"icon":_vm.ourConnection ? 'fa-regular fa-user-check' : 'fa-regular fa-user-plus',"icon-classes":_vm.ourConnection &&
                _vm.ourConnection.linkState === _vm.connectionStatusType.INVITED
                ? 'text-neutral-n-5-placeholder' : '',"container-classes":"icon-24"}})]},proxy:true}],null,false,1769444380)}):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"banner"},[(_vm.bannerFileResource)?_c('img',{staticClass:"image",attrs:{"src":_vm.FileResourceHelper.getFullPath(_vm.bannerFileResource, 'w256'),"alt":"member banner"}}):_vm._e(),(_vm.community.defaultUserBannerFileResource)?_c('img',{staticClass:"image",attrs:{"src":_vm.FileResourceHelper.getFullPath(_vm.community.defaultUserBannerFileResource, 'w256'),"alt":"member banner"}}):_c('div',{staticClass:"no-banner"})]),_c('div',{staticClass:"avatar"},[_c('avatar-solo-widget',{attrs:{"default-icon":'far fa-circle-user',"firstname":_vm.firstName,"lastname":_vm.lastName,"recommended-for-you":_vm._isRecommendedForMe && _vm.displayRecommendedBadge,"src":_vm.FileResourceHelper.getFullPath(_vm.pictureFileResource, 'w96'),"with-borders":true,"size":"96"}})],1),_c('div',{staticClass:"infos pl-3 pr-3"},[_c('div',{staticClass:"d-inline user-name"},[_c('h6',{staticClass:"name text-neutral-n-8-dark-ink mt-2 mb-2 d-inline"},[_vm._v(" "+_vm._s(_vm.getFullPrefixedName)+" ")]),(_vm.countryFlag)?_c('div',{staticClass:"country-container d-inline-flex align-items-end ml-1",attrs:{"id":'tool-item-'+_vm.uid}},[_c('img',{attrs:{"alt":(_vm.countryFlag + " flag"),"src":("https://cdn.ipregistry.co/flags/emojitwo/" + (_vm.countryFlag.toLowerCase()) + ".svg"),"height":"12"}}),(_vm.address && _vm.address.country && _vm.address.country.name)?_c('b-tooltip',{attrs:{"target":'tool-item-'+_vm.uid,"title":_vm.address.country.name,"custom-class":"tooltip-style","placement":"top"}}):_vm._e()],1):_vm._e()]),(_vm.jobTitleEmployer)?_c('p',{staticClass:"job text-neutral-n-6-label mb-0 label-2"},[_vm._v(" "+_vm._s(_vm.jobTitleEmployer)+" ")]):_vm._e()]),(_vm.hasConnectionFeature)?_c('div',{staticClass:"action-button"},[(_vm.ourConnection === null
          && _vm.isProfileVisible
          && _vm.buttonsEnabled
          && _vm.ActionRuleService.isRuleValid(_vm._actions, _vm.ActionRule.CAN_CONNECT))?_c('div',{staticClass:"button-container"},[_c('button-component',{attrs:{"rounded":true,"text":("" + (_vm.$t('actions.add-connection'))),"size":"md","variant":"secondary card-height"},on:{"on-click":_vm.toggleModal}})],1):(_vm.ourConnection
          && _vm.hasConnectionFeature
          && _vm.ourConnection.linkState === _vm.connectionStatusType.INVITED
          && _vm.buttonsEnabled
          && _vm.ActionRuleService.isRuleValid(_vm._actions, _vm.ActionRule.CAN_CONNECT))?_c('div',[(_vm.ourConnection.connectedUser
            && _vm.ourConnection.connectedUser.uid === _vm.authUser.uid)?_c('div',{staticClass:"button-container"},[_c('button-component',{attrs:{"rounded":true,"text":("" + (_vm.$t('actions.accept-connection-request'))),"size":"md","variant":"secondary card-height"},on:{"on-click":_vm.onAccept}}),_c('button-component',{attrs:{"rounded":true,"text":("" + (_vm.$t('actions.decline-connection-request'))),"size":"md","variant":"secondary-red card-height"},on:{"on-click":_vm.onDecline}})],1):_c('div',{staticClass:"button-container"},[_c('button-component',_vm._b({attrs:{"rounded":true,"text":("" + (_vm.$t('actions.awaiting-invited-contact'))),"size":"md","variant":"secondary card-height card-style-disabled"},on:{"on-click":false},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-user-check","icon-classes":"text-neutral-n-5-placeholder"}})]},proxy:true}],null,false,4182493461)},'button-component',_vm.useTestDataAttribute('pending-connection-button'),false))],1)]):(_vm.ourConnection
          && _vm.ourConnection.linkState === _vm.connectionStatusType.ACCEPTED
          && _vm.buttonsEnabled
          && _vm.ActionRuleService.isRuleValid(_vm._actions, _vm.ActionRule.CAN_CONNECT))?_c('div',{staticClass:"button-container"},[(_vm.isFeatureActivated(_vm.FeatureKeys.COMMUNITY_CHAT_FEATURE))?_c('button-icon-component',{attrs:{"rounded":true,"size":"md","variant":"secondary"},on:{"on-click":function($event){return _vm.openConversation()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-comment-dots"}})]},proxy:true}],null,false,1310490291)}):_vm._e(),(_vm.isFeatureActivated(_vm.FeatureKeys.COMMUNITY_AGENDA_FEATURE)
            && _vm.ActionRuleService.isRuleValid(_vm._actions, _vm.ActionRule.CAN_MEET))?_c('button-icon-component',{attrs:{"rounded":true,"size":"md","variant":"secondary"},on:{"on-click":function($event){return _vm.createMeeting()}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-component',{attrs:{"container-classes":"icon-24","icon":"fa-regular fa-calendar-plus"}})]},proxy:true}],null,false,402119076)}):_vm._e()],1):_vm._e(),(_vm.ActionRuleService.isRuleValid(_vm._actions, _vm.ActionRule.CAN_CONNECT)
          && _vm.hasConnectionFeature)?_c('div',[(_vm.authUser && _vm.ourConnection)?_c('b-tooltip',{attrs:{"target":("connect-" + _vm.uid),"title":_vm.ourConnection.linkState === _vm.connectionStatusType.INVITED
            ? _vm.$t('actions.tooltip.connect.awaitingReply')
            : _vm.$t('actions.tooltip.connect.remove'),"boundary":"document","custom-class":"tooltip-style","placement":"top","triggers":"hover"}}):(_vm.authUser && _vm.isProfileVisible)?_c('b-tooltip',{attrs:{"target":("connect-" + _vm.uid),"title":_vm.$t('actions.tooltip.connect.add'),"boundary":"document","custom-class":"tooltip-style","placement":"top","triggers":"hover"}}):_vm._e()],1):_vm._e()]):_vm._e()]),_c('confirm-modal',{attrs:{"cancel-text":("" + (_vm.$t('component.user-card.modal.button.cancel'))),"description":("" + (_vm.$t('component.user-card.modal.remove-connection.message',
                        { name: ("<b>" + _vm.firstName + " " + _vm.lastName + "</b>") }))),"ok-text":("" + (_vm.$t('component.user-card.modal.button.confirm'))),"title":("" + (_vm.$t('component.user-card.modal.remove-connection.title'))),"user-id":_vm.uid,"type":"user-card-remove-connection"},on:{"on-confirm":_vm.toggleConnection}}),_c('send-connection-request-modal',{attrs:{"name":(_vm.firstName + " " + _vm.lastName),"user-id":_vm.uid,"type":"user-card-issue-connection-request"},on:{"on-confirm":_vm.toggleConnection}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }